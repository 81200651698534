@import url('https://fonts.googleapis.com/css2?family=Spline+Sans:wght@300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nohemi';
  src: url('../src/assets/Fonts/Nohemi-Medium-BF6438cc581a509.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width, 6px);
  height: var(--scrollbar-width, 6px);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(32, 29, 29, 0);
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

body {
  background-color: #f2efe9;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}